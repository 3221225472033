import {Injectable} from '@angular/core';
import {RestService} from '@nit-core/services/global/http-services/rest.service';
import {Course} from '@nit-core/models';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CourseService extends RestService<Course> {
  course: BehaviorSubject<Course> = new BehaviorSubject<Course>(null);
  constructor() {
    super(Course, '/courses', 'courses');
  }

  getCourse(): Observable<Course> {
    return this.course;
  }

  updatePermissions(id: string, data: any): Observable<any> {
    return this.httpPost(`${this.apiHref()}/${id}/permissions`, data);
  }
}
